import * as React from 'react';
import '../styles/App.less';
import {setUTMCookie} from '../utils/CookieUtil';
import {PageProps} from 'gatsby';
import Auth0 from '../components/Login/Auth0';
import Page from '../components/Layout/Page';

const IndexPage = (props: PageProps) => {
  setUTMCookie(props);
  return (
    <Page mainClass={'main-holder-auth0'} title={'auth0'}>
      <Auth0 />
    </Page>
  );
};

export default IndexPage;
